import React, { useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Button, Box, CircularProgress } from '@mui/material';

import { useApplyDealWithEmail } from '../../../utils/hooks/useApplyDealWithEmail';

export const ApplyDealWithEmailButton = ({ dealId, email, body, onResponse, validateEmail }) => {
  const {
    loading: applying,
    result,
    triggerFunc,
    error: fetchError,
  } = useApplyDealWithEmail({ dealId, email, body });

  useEffect(() => {
    if (!R.isEmpty(result)) {
      const { ok, status: responseStatus, statusText } = result;
      if (!ok) {
        onResponse(
          `Failed to register user on assignment: ${responseStatus} ${statusText}`,
          true,
          'error',
        );
      } else {
        onResponse(`User successfully registered on assignment`, true, 'success');
      }
    } else if (fetchError) {
      const { status: responseStatus, statusText: responseText } = fetchError.response;
      onResponse(
        `Failed to register user on assignment: ${responseStatus} ${responseText}`,
        true,
        'error',
      );
    }
  }, [result, onResponse, fetchError]);

  const handleClick = () => {
    if (validateEmail && !validateEmail()) {
      return;
    }
    triggerFunc();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        mb: 4,
        mt: 4,
        width: 190,
        height: 40,
      }}
    >
      <Button
        variant="contained"
        fullWidth
        sx={{ height: 40, borderRadius: 6, p: 1 }}
        disabled={applying}
        onClick={handleClick}
      >
        {applying ? 'Sending application' : 'Apply'}
      </Button>
      {applying && (
        <CircularProgress
          size={22}
          thickness={6}
          sx={{
            position: 'absolute',
            top: '25%',
            left: '43%',
          }}
        />
      )}
    </Box>
  );
};

ApplyDealWithEmailButton.propTypes = {
  dealId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  body: PropTypes.string,
  onResponse: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
};
