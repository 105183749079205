import React, { useState, useCallback } from 'react';
import {
  Modal,
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  FormHelperText,
  Alert,
  Snackbar,
} from '@mui/material';
import { ApplyDealWithEmailButton } from './ApplyDealWithEmailButton';
import { CancelDealWithEmailButton } from './CancelDealWithEmailButton';

const initialAlertState = { message: '', open: false, variant: 'error' };

export const DealDetailsRegisterUserModal = ({ open, close, dealId }) => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [alert, setAlert] = useState(initialAlertState);
  const [snackBarOpen, setSnackBarOpen] = useState(false);

  const onResponse = useCallback((msg, state, type) => {
    setAlert({ message: msg, open: state, variant: type });
    setSnackBarOpen(true);
  }, []);

  const validateEmail = (inputEmail) => {
    // Attempt to match a valid email string
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(inputEmail);
  };

  const handleClose = () => {
    setEmail('');
    setEmailError('');
    close();
  };

  const handleEmailValidation = () => {
    if (!email) {
      setEmailError('Email is required');
      return false;
    }
    if (!validateEmail(email)) {
      setEmailError('Invalid email address');
      return false;
    }
    return true;
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '30%',
          minWidth: 500,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 2 }}>
          Register interest for user
        </Typography>
        <TextField
          fullWidth
          label={
            <span>
              Email <span style={{ color: 'red' }}>*</span>
            </span>
          }
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            setEmailError('');
          }}
          error={!!emailError}
        />

        {emailError && <FormHelperText error>{emailError}</FormHelperText>}
        <Grid container spacing={4} sx={{ mb: 4 }}>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ApplyDealWithEmailButton
              dealId={Number(dealId)}
              email={email}
              validateEmail={handleEmailValidation}
              onResponse={onResponse}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <CancelDealWithEmailButton
              dealId={Number(dealId)}
              email={email}
              validateEmail={handleEmailValidation}
              onResponse={onResponse}
            />
          </Grid>
          <Grid
            item
            xs={4}
            sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
          >
            <Box
              sx={{
                display: 'flex',
                direction: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                mb: 4,
                mt: 4,
                width: 190,
                height: 40,
              }}
            >
              <Button
                variant="outlined"
                onClick={handleClose}
                fullWidth
                sx={{ height: 40, borderRadius: 6, p: 1 }}
              >
                Back
              </Button>
            </Box>
          </Grid>
        </Grid>

        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={snackBarOpen}
          autoHideDuration={10000}
          onClose={() => setSnackBarOpen(false)}
          sx={{ width: '100%' }}
        >
          <Alert onClose={() => setSnackBarOpen(false)} severity={alert.variant}>
            {alert.message}
          </Alert>
        </Snackbar>
      </Box>
    </Modal>
  );
};
