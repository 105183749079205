import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useProperties = ({ objectType, propertyName }) => {
  const endpoint = useMemo(
    () => `${getApiUrl()}/properties/${objectType}/${propertyName}`,
    [objectType, propertyName],
  );
  return useRequest({
    endpoint,
    method: 'GET',
  });
};
