import { TextField, FormHelperText } from '@mui/material';
import { Controller } from 'react-hook-form';

export const FormText = ({ name, label, defaultValue = '', control, edit, errors, ...other }) => {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field }) => (
        <>
          <TextField
            fullWidth
            size="small"
            {...field}
            label={!edit ? label : ''}
            control={control}
            {...other}
          />
          {errors && name === 'location' && errors.location && (
            <FormHelperText error> {errors.location.message} </FormHelperText>
          )}
          {errors && name === 'lastDayForBid' && errors.lastDayForBid && (
            <FormHelperText error> {errors.lastDayForBid.message} </FormHelperText>
          )}
        </>
      )}
    />
  );
};
