import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useApplyDealWithEmail = ({ dealId, email, body = '' }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/deals/${dealId}/apply/${email}`, [dealId, email]);
  return useRequest({
    endpoint,
    method: 'POST',
    externalTrigger: true,
    body,
    clearResultAndError: true, // For ApplyDealWithEmailButton since there might be multiple requests
  });
};
