import React, { useEffect } from 'react';
import * as R from 'ramda';
import PropTypes from 'prop-types';
import { Button, Box, CircularProgress } from '@mui/material';

import { useCancelDealWithEmail } from '../../../utils/hooks/useCancelDealWithEmail';

export const CancelDealWithEmailButton = ({ dealId, email, onResponse, validateEmail }) => {
  const {
    loading: canceling,
    result,
    triggerFunc,
    error: fetchError,
  } = useCancelDealWithEmail({ dealId, email });

  useEffect(() => {
    if (!R.isEmpty(result)) {
      const { ok, status: responseStatus, statusText } = result;
      if (!ok) {
        onResponse(
          `Failed to remove user from assignment: ${responseStatus} ${statusText}`,
          true,
          'error',
        );
      } else {
        onResponse(`User successfully removed from assignment`, true, 'success');
      }
    } else if (fetchError) {
      const { status: responseStatus, statusText: responseText } = fetchError.response;
      onResponse(
        `Failed to remove user from assignment: ${responseStatus} ${responseText}`,
        true,
        'error',
      );
    }
  }, [result, onResponse, fetchError]);

  const handleClick = () => {
    if (validateEmail && !validateEmail()) {
      return;
    }
    triggerFunc();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        direction: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'relative',
        mb: 4,
        mt: 4,
        width: 190,
        height: 40,
      }}
    >
      <Button
        variant="contained"
        fullWidth
        sx={{ height: 40, borderRadius: 6, p: 1 }}
        disabled={canceling}
        onClick={handleClick}
      >
        {canceling ? 'Canceling application' : 'Cancel'}
      </Button>
      {canceling && (
        <CircularProgress
          size={22}
          thickness={6}
          sx={{
            position: 'absolute',
            top: '25%',
            left: '43%',
          }}
        />
      )}
    </Box>
  );
};

CancelDealWithEmailButton.propTypes = {
  dealId: PropTypes.number.isRequired,
  email: PropTypes.string.isRequired,
  onResponse: PropTypes.func.isRequired,
  validateEmail: PropTypes.func.isRequired,
};
