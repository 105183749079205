/* eslint-disable max-lines-per-function, max-lines, complexity, react/no-array-index-key */
import {
  MenuItem,
  Link,
  FormHelperText,
  CircularProgress,
  TextField,
  Autocomplete,
  Select,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import * as R from 'ramda';

import { toNumber } from '../../../utils/utils';

const sortList = (list = []) => R.sortBy(R.prop('name'))(list);

export const FormSelect = ({
  valueId,
  itemKey,
  value,
  data,
  customerList,
  formParams: { control, setValue, watch },
  link,
  loading,
  errors,
  hasMoreCompany,
  loadMoreCompany,
  companyOffset = 0,
  search = false,
  searchQuery = '',
  handleSearchChange,
}) => {
  const [SelectObject, setSelectObject] = useState({});
  const [companyList, setCompanyList] = useState([]);
  const [companyFilterList, setCompanyFilterList] = useState([]);
  const [offset, setOffset] = useState(0);
  const [selectedCustomer, setSelectedCustomer] = useState('');

  useEffect(() => {
    setSelectedCustomer(R.find(R.propEq('key', valueId))(companyList));
  }, [companyList, valueId]);

  useEffect(() => {
    if (searchQuery !== '' && customerList) {
      setCompanyFilterList(R.uniqBy(R.prop('name'))(customerList));
    } else {
      if (offset !== companyOffset) {
        if (customerList) {
          setCompanyList((currentList) =>
            R.uniqBy(R.prop('name'))([...currentList, ...customerList]),
          );
          setCompanyFilterList([]);
        }
      }
      setOffset((prevValue) => {
        if (prevValue !== companyOffset) {
          return companyOffset;
        }
        return prevValue;
      });
    }
  }, [customerList, offset, companyOffset, searchQuery]);

  const currentValue = watch(itemKey);

  useEffect(() => {
    if (itemKey === 'customerId') {
      setValue('currentCustomerId', toNumber(valueId));
    }
    if (itemKey === 'frameworkAgreement.name')
      setValue('frameworkAgreement.frameworkAgreementCurrentId', toNumber(value));
  }, [setValue, value, valueId, itemKey]);

  useEffect(() => {
    setValue(
      itemKey,
      SelectObject.defaultValue && SelectObject.defaultValue !== ''
        ? itemKey === 'customerId'
          ? toNumber(SelectObject.defaultValue)
          : SelectObject.defaultValue
        : '',
    );
  }, [SelectObject.defaultValue, setValue, itemKey]);

  useEffect(() => {
    if (itemKey === 'customerId') {
      setSelectObject({
        id: 'customer-list',
        list: sortList(searchQuery === '' ? companyList : companyFilterList),
        hasMore: true,
      });
    } else if (itemKey === 'workModel') {
      setSelectObject({
        id: 'work-model-list',
        list: sortList(data.workmodelList),
        defaultValue: value,
      });
    } else if (itemKey === 'typeOfRequest') {
      setSelectObject({
        id: 'type-of-request',
        list: sortList(data.typeOfRequest),
        defaultValue: value,
      });
    } else if (itemKey === 'frameworkAgreement.name') {
      setSelectObject({
        id: 'framework-agreement',
        list: sortList(data.fa_list),
        defaultValue: value,
      });
    } else if (itemKey === 'publishInBusinessPortal') {
      setSelectObject({
        id: 'publish-in-business-portal',
        list: data.publishInBusinessPortalList,
        defaultValue: R.toString(value),
      });
    } else if (itemKey === 'country') {
      setSelectObject({
        id: 'country-list',
        // Do not sort in order to keep same order as in HubSpot
        list: data.countryPropertiesList,
        defaultValue: value,
      });
    }
    return undefined;
  }, [data, itemKey, value, companyList, companyFilterList, searchQuery]);

  const linkComponent = useMemo(() => {
    if (link) {
      return currentValue && SelectObject.list ? (
        <Link href={`/framework-agreements/${currentValue}`}>
          {R.prop(
            'name',
            R.find(R.pipe(R.prop('key'), R.toString, R.equals(R.toString(currentValue))))(
              SelectObject.list || [],
            ),
          )}
        </Link>
      ) : (
        ''
      );
    }
    return '';
  }, [SelectObject.list, currentValue, link]);

  const handleSelectChange = (newValue) => {
    setSelectedCustomer(newValue);
    setValue(itemKey, newValue, { shouldDirty: true });
    const isFA = R.all(R.has('contracting_party_supplier'))(SelectObject.list);
    if (isFA) {
      const newContractingPartySupplier = R.prop(
        'contracting_party_supplier',
        R.find(R.propEq('key', newValue), SelectObject.list),
      );
      setValue(
        'frameworkAgreement.contractingPartySupplier',
        R.isNil(newContractingPartySupplier) ? '' : newContractingPartySupplier,
      );
      const newContractingPartySupplierId = R.prop(
        'contracting_party_supplier_id',
        R.find(R.propEq('key', newValue), SelectObject.list),
      );
      setValue('frameworkAgreement.contractingPartySupplierId', newContractingPartySupplierId);
    }
  };

  const onLoadMoreCompany = useCallback(
    async (event) => {
      event.preventDefault();
      event.stopPropagation();
      await loadMoreCompany();
    },
    [loadMoreCompany],
  );

  const handleKeyDown = (event) => {
    if (event.key === 'Enter' || event.key === ' ') {
      onLoadMoreCompany(event);
    }
  };

  const handleInputchange = useCallback(
    (e, searchValue) => {
      if (searchValue === '') handleSearchChange(searchValue);
      else if (e !== null && e.type === 'change') handleSearchChange(searchValue);
    },
    [handleSearchChange],
  );

  return (
    <Controller
      name={itemKey}
      control={control}
      rules={{
        validate: (publish) =>
          publish !== 'false' || 'It is required to choose Yes to publish the deal',
      }}
      render={({ field }) => (
        <>
          {link && linkComponent}
          {search ? (
            <Autocomplete
              filterOptions={(x) => x}
              onChange={(e, selectedValue) => handleSelectChange(selectedValue)}
              onInputChange={(e, searchValue) => handleInputchange(e, searchValue)}
              value={selectedCustomer}
              options={SelectObject.list || []}
              getOptionLabel={(option) => option.name || ''}
              loading={loading}
              renderOption={(props, option, state) => {
                return SelectObject.hasMore &&
                  hasMoreCompany &&
                  SelectObject.list.length - 1 === state.index ? (
                  searchQuery === '' && (
                    <MenuItem
                      {...props}
                      key={option.name + option.key}
                      value={option.key}
                      onClick={onLoadMoreCompany}
                      style={{
                        backgroundColor: '#f5f5f5',
                        fontWeight: 'bold',
                        textAlign: 'center',
                      }}
                    >
                      <div
                        role="button"
                        x={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                        tabIndex={0}
                        onClick={onLoadMoreCompany}
                        onKeyDown={handleKeyDown}
                      >
                        {loading ? <CircularProgress size={20} /> : 'Load more'}
                      </div>
                    </MenuItem>
                  )
                ) : (
                  <MenuItem {...props}>{option.name}</MenuItem>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Select Company"
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loading ? <CircularProgress color="inherit" size={20} /> : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
          ) : (
            <Select
              labelId={SelectObject.id}
              disableUnderline
              {...field}
              variant="standard"
              id={SelectObject.id}
              onChange={(e) => handleSelectChange(e.target.value)}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'center',
                },
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    minWidth: 100,
                  },
                },
              }}
              sx={{
                '& .MuiSelect-select': {
                  padding: '4px 0 5px 4px',
                },
                maxWidth: '75%',
              }}
              autoWidth
            >
              {SelectObject.list &&
                SelectObject.list.map(({ name, key }) => {
                  return (
                    <MenuItem sx={{ padding: 1 }} key={name + key} value={key}>
                      {name}
                    </MenuItem>
                  );
                })}
            </Select>
          )}
          {errors && itemKey === 'publishInBusinessPortal' && errors.publishInBusinessPortal && (
            <FormHelperText error> {errors.publishInBusinessPortal.message} </FormHelperText>
          )}
          {errors && itemKey === 'country' && errors.country && (
            <FormHelperText error> {errors.country.message} </FormHelperText>
          )}
          {errors && itemKey === 'frameworkAgreement.name' && errors.frameworkAgreement?.name && (
            <FormHelperText error> {errors.frameworkAgreement?.name.message} </FormHelperText>
          )}
        </>
      )}
    />
  );
};
