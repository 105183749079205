import { useMemo } from 'react';

import { useRequest } from './useRequest';
import { getApiUrl } from '../utils';

export const useCancelDealWithEmail = ({ dealId, email }) => {
  const endpoint = useMemo(() => `${getApiUrl()}/deals/${dealId}/cancel/${email}`, [dealId, email]);
  return useRequest({
    endpoint,
    method: 'GET',
    externalTrigger: true,
    clearResultAndError: true, // For CancelDealWithEmailButton since there might be multiple requests
  });
};
